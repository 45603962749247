<template>
    <v-autocomplete
        ref="select"
        :items="pages"
        :value="value"
        item-text="name"
        item-value="id"
        return-object
        :menu-props="{
            contentClass: 'dealer-menu',
            maxHeight: 290
        }"
        :loading="loading ? 'primary' : false"
        :rules="rules"
        class="dealerSelect dealer-select styled-select"
        append-icon="arrow_drop_down"
        light
        color="grey lighten-2"
        label="Page"
        placeholder="Search pages..."
        :error-messages="errors"
        hide-no-data
        no-filter
        @input="$emit('input', $event)"
        @keyup="search">
        <template #item="{ item }">
            <v-list-tile-content>
                <v-list-tile-title>{{ item.name }}</v-list-tile-title>
                <v-list-tile-sub-title class="grey--text text--lighten-2">
                    <template v-if="item.location">
                        {{ item.location.city }}, {{ item.location.state }} {{ item.location.country }}
                    </template>
                    <template v-else>
                        No location
                    </template>
                </v-list-tile-sub-title>
            </v-list-tile-content>
        </template>
        <template #no-data>
            <v-list-tile>
                <v-list-tile-title>
                    {{ (loading) ? 'Loading...' : 'No pages found' }}
                </v-list-tile-title>
            </v-list-tile>
        </template>
    </v-autocomplete>
</template>

<script>
import { debounce } from 'lodash';

export default {
    props: {
        value: [ Object, null ],
        rules: {
            type: Array,
            default: () =>[]
        }
    },
    data() {
        return {
            pages: [],
            loading: false,
            errors: []
        };
    },
    created() {
        // If the component is initialized with a value ensure
        // it's in the list
        if (this.value) {
            this.pages.push(this.value);
        }
    },
    methods: {
        search: debounce(async function(e) {
            try {
                this.loading = true;
                this.pages = [];
                this.errors = [];

                const { value } = e.target;

                if (!value.length) {
                    // Reset the page
                    this.$emit('input', null);
                    return;
                }

                const response = await this.$http.get('facebook/search/page', {
                    q: value,
                    fields: 'id,name,location,link'
                });
                this.pages = response.data.data;
                if (!this.pages.length) {
                    this.errors = ['No pages could be found matching this search, please try again'];
                }
            } catch (error) {
                console.error(error);
                this.errors = ['Error retrieving results from Facebook'];
            } finally {
                this.loading = false;
            }
        }, 500)
    }
};
</script>
