<template>
    <div>
        <styled-slideout
            v-if="page"
            v-model="messagingDialogOpen">
            <div class="pa-5">
                <h2>Page Access Messaging</h2>
                <p>
                    Use the example messaging below as a starting point to
                    communicate with the owner of {{ page.name }}
                </p>
                <copy-text-area
                    hide-download
                    :email-subject="`Facebook Page Access ${hasMultipleBms ? 'Requests' : 'Request'} for ${page.name}`"
                    class="mb-4">
                    <p>
                        Greetings,
                    </p>
                    <p v-if="hasMultipleBms">
                        {{ bmNames }} are requesting access to the
                        Facebook page for your business.
                    </p>
                    <p v-else>
                        {{ bmNames }} is requesting access to the
                        Facebook page for your business.
                    </p>
                    <p>
                        Please click the URL below and scroll down to the "Respond to Request"
                        button to accept {{ hasMultipleBms ? 'these requests' : 'the request' }}:<br>
                        {{ pageAccessUrl }}
                    </p>
                    <p>
                        You must accept {{ hasMultipleBms ? 'these requests' : 'the request' }}
                        to allow {{ currentDealer.agency.name }} to advertise on Facebook using your page.
                    </p>
                    <p>
                        {{ hasMultipleBms ? 'These requests need' : 'The request needs' }} to be accepted
                        by the <strong>page owner</strong>. If you do not see a "Respond to Request" button
                        towards the bottom of the page it is likely because you are not the page owner
                        so you will need to forward this message to them.
                    </p>
                    <p v-if="hasMultipleBms">
                        Please note that the requests will come from <strong>{{ bmNames }}</strong>
                        and <strong>both</strong> requests will need to be accepted to ensure all services
                        are active.
                    </p>
                    <p>
                        Thanks!<br>
                        The {{ currentDealer.agency.name }} Team
                    </p>
                </copy-text-area>

                <p>
                    If you'd like to use your own messaging you can copy the
                    access approval URL below.
                </p>
                <copy-text-area
                    hide-download
                    hide-email>
                    {{ pageAccessUrl }}
                </copy-text-area>
            </div>
        </styled-slideout>

        <v-alert
            :value="error"
            class="mb-4">
            {{ error }}
        </v-alert>

        <template v-if="onboardingPageSetupIncomplete">
            <h2>Connect a Facebook Page</h2>
            <p>
                You'll need to associate a Facebook page with {{ currentDealer.name }}
                to use most Meta features. If you already have access to the
                page select it in your business manager.  Otherwise, you can request
                access to a new page here.
            </p>
            <v-form
                v-model="valid"
                @submit.prevent="() => false">
                <v-radio-group v-model="pageAccessType">
                    <div class="layout align-center">
                        <v-radio
                            label="Request access to a page"
                            value="new"
                            color="primary"
                            class="mb-0" />
                        <v-radio
                            label="Select a page from Business Manager"
                            value="existing"
                            color="primary" />
                    </div>
                </v-radio-group>

                <template v-if="pageAccessType == 'existing'">
                    <v-autocomplete
                        v-model="page"
                        label="Select a Page"
                        class="mb-3 dealerSelect dealer-select styled-select"
                        item-text="name"
                        item-value="id"
                        :items="bmPages"
                        :loading="loading"
                        :rules="requiredRules"
                        placeholder="Select..."
                        return-object />
                </template>

                <template v-if="pageAccessType == 'new'">
                    <p>
                        Okay!  Let's get page access!  You can search for a page or enter a URL to it below 👇
                    </p>

                    <div class="layout wrap">
                        <div class="flex xs12 md4">
                            <v-select
                                v-model="newPageInputType"
                                label="Search or Enter URL?"
                                :items="newPageInputTypeOptions"
                                class="mb-4 dealerSelect dealer-select styled-select mr-3" />
                        </div>

                        <div class="flex xs12 md8">
                            <page-search
                                v-if="newPageInputType == 'search'"
                                v-model="page"
                                class="mb-4"
                                :rules="requiredRules" />

                            <v-text-field
                                v-if="newPageInputType == 'url'"
                                ref="pageUrlField"
                                v-model="pageUrl"
                                label="Facebook page URL"
                                class="mb-4 dealerSelect dealer-select styled-select"
                                placeholder="https://www.facebook.com/your-page/"
                                :loading="loading"
                                :error-messages="pageUrlErrors"
                                :rules="[ ...facebookUrlRules, ...requiredRules]"
                                @keyup="onPageUrlInput" />
                        </div>
                    </div>

                    <div v-if="page && hasPageAccess">
                        <div class="layout align-center page-callout mb-3">
                            <div class="mr-3">
                                <icon
                                    name="indicator-success"
                                    :color="colors.green" />
                            </div>
                            <div>
                                It looks like you already have access to {{ page.name }}
                                so you're all good and can continue!
                            </div>
                        </div>
                    </div>

                    <div v-else-if="page">
                        <div class="layout align-center page-callout mb-3">
                            <div class="mr-3">
                                <icon
                                    name="indicator-warning"
                                    :color="colors.yellow" />
                            </div>
                            <div>
                                You will need to work directly with the Facebook page admin of
                                {{ page.name }} ({{ page.id }}) to accept the access
                                {{ hasMultipleBms ? 'requests' : 'request' }}
                                we're about to initiate before you can finalize the onboarding process
                                (<a
                                    href="#"
                                    @click.prevent="messagingDialogOpen = true">
                                    <span>see example messaging</span>
                                </a>).
                                Note that the {{ hasMultipleBms ? 'requests' : 'request' }}
                                will come from <strong>{{ bmNames }}'s</strong>
                                business {{ hasMultipleBms ? 'managers' : 'manager' }}.
                            </div>
                        </div>
                        <div class="mb-4">
                            <a
                                href="https://support.buyerbridge.com/knowledge/facebook-page-access"
                                target="_blank">
                                Questions?  Click here for our help article on requesting &amp; approving page access
                            </a>
                        </div>
                    </div>
                </template>
            </v-form>
        </template>
        <template v-else>
            <template v-if="onboardingPageAccessComplete">
                <h2>Facebook Page Access Complete!</h2>
                <p class="mb-5">
                    {{ currentDealer.name }} has granted all the necessary page access
                    you'll need to use BuyerBridge.  Nice work!
                </p>
            </template>
            <template v-else>
                <h2>Facebook Page Access Pending</h2>
                <p class="mb-5">
                    You have configured the page but still need to have a page
                    admin at {{ currentDealer.name }} accept access. If you need
                    some guidance on communications
                    <a
                        href="#"
                        @click.prevent="messagingDialogOpen = true">
                        <span>click here to see example messaging</span>
                    </a>.
                </p>
            </template>
            <manage-dealer-page
                :dealer="currentDealer"
                :completed="completed"
                class="mb-5"
                @update="onDealerPageUpdate" />
        </template>

        <div class="layout align-center">
            <!-- <a
                href="#"
                @click.prevent="$store.dispatch('goToPreviousStep')">
                back
            </a> -->
            <back-button @click="$store.dispatch('goToPreviousStep')" />
            <styled-button
                class="ml-auto"
                :loading="loadingPageRequest"
                :disabled="onboardingPageSetupIncomplete ? (!valid || !page) : false"
                @click="onContinue">
                {{ (page && !hasPageAccess) ? 'Request Access & Continue' : 'Continue' }}
            </styled-button>
        </div>
    </div>
</template>

<script>
import BackButton from '../../../globals/BackButton';
import colors from '../../../../helpers/colors';
import { requiredRules, multipleEmailRules, booleanRules, facebookUrlRules } from '../../../../helpers/validationRules';
import ManageDealerPage from '../../../globals/ManageDealerPage';
import CopyTextArea from '../../../globals/CopyTextArea';
import StyledButton from '../../../globals/StyledButton';
import StyledSlideout from '../../../globals/StyledSlideout';
import PageSearch from '../../../globals/PageSearch';
import { mapState, mapGetters } from 'vuex';
import debounce from 'debounce';
import { get } from 'lodash';
import Icon from '../../../globals/Icon';
import { UPDATE_ONBOARDING_DATA } from '../../../../store/mutation-types';

export default {
    name: 'FacebookPage',
    components: {
        Icon,
        PageSearch,
        StyledButton,
        StyledSlideout,
        CopyTextArea,
        ManageDealerPage,
        BackButton
    },
    data() {
        return {
            colors,
            error: null,
            pageUrlIsValid: null,
            pageUrlErrors: [],
            page: null,
            requiredRules,
            multipleEmailRules,
            facebookUrlRules,
            booleanRules,
            accessRequestEmails: '',
            loading: false,
            bmPages: [],
            pageUrl: '',
            completed: false,
            type: null,
            valid: false,
            shouldSendAccessRequest: null,
            loadingPageRequest: false,
            messagingDialogOpen: false,
            requiredBms: [],
            newPageInputTypeOptions: [{
                value: 'search',
                text: 'Search Pages'
            },{
                value: 'url',
                text: 'Enter Page URL'
            }]
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
            onboardingData: (state) => state.onboarding.data
        }),
        ...mapGetters([
            'onboardingStatus',
            'currentStepActiveFeatures'
        ]),
        hasPageAccess() {

            if (!this.page) {
                return false;
            }

            return this.bmPages.some(page => {
                return (page.id === this.page.id);
            });
        },
        pageAccessUrl() {
            if (!this.page) {
                return '';
            }

            return `https://www.facebook.com/${this.page.id}/settings/?tab=admin_roles&ref=page_edit`;
        },
        bmNames() {
            return this.requiredBms.map(bm => bm.name).join(' and ');
        },
        hasMultipleBms() {
            return this.requiredBms > 1;
        },
        onboardingPageStatus() {
            return this.onboardingStatus.find(feature => ['facebook_marketplace_page', 'facebook_page'].includes(feature.feature));
        },
        onboardingPageSetupIncomplete() {
            return (this.onboardingPageStatus.status === 'missing_initial_setup');
        },
        onboardingPageAccessComplete() {
            return (this.onboardingPageStatus.status === 'complete');
        },
        pageAccessType: {
            get() {
                return this.onboardingData.pageAccessType || null;
            },
            set(type) {
                this.$store.commit(UPDATE_ONBOARDING_DATA, {
                    pageAccessType: type
                });
            }
        },
        newPageInputType: {
            get() {
                return this.onboardingData.newPageInputType || 'search';
            },
            set(type) {
                this.$store.commit(UPDATE_ONBOARDING_DATA, {
                    newPageInputType: type
                });
            }
        }
    },
    watch: {
        pageAccessType() {
            // Reset the page when the access type changes
            this.page = null;
        },
        newPageInputType() {
            // Reset the page when the page input type changes
            this.page = null;
        }
    },
    created() {
        this.getPages();
        this.getRequiredBusinessManagers();
    },
    methods: {
        async getRequiredBusinessManagers() {
            try {
                this.requiredBms = [];
                const response = await this.$http.get(`/dealers/${this.currentDealer.id}/required-business-manager-page-access`);
                this.requiredBms = response.data.data;
            } catch (error) {
                console.error('Error retrieving required business managers', error);
            }
        },
        async getPages() {
            this.loading = true;
            const response = await this.$apiRepository.getAgencyPages(this.currentDealer.agency.id);
            this.bmPages = response.data.data;
            this.loading = false;
        },
        async getPage(id) {
            try {
                this.loading = true;
                const response = await this.$http.get(`/facebook/pages/${id}`, {
                    fields: 'id,name,picture,link',
                    page_size: 1000
                });
                this.page = response.data;
            } catch(error) {
                console.error('Error retrieving Faceobook page', error);
                // If the request is coming in as a Page URL validation
                // consider the failure here as an invalid page URL
                if (this.pageUrl) {
                    this.pageUrlIsValid = false;
                    this.pageUrlErrors = ['There was an error validating your page URL.  Please ensure it\'s correct.'];
                }
            } finally {
                this.loading = false;
            }
        },
        async validatePageUrl(url) {
            try {
                // Reset all relevant data before processing
                this.pageUrlIsValid = null;
                this.pageUrlErrors = [];
                this.page = null;

                // Only act if the field has been validated
                if (!this.$refs.pageUrlField.valid) {
                    return;
                }

                this.loading = true;
                const response = await this.$http.post('/scraping/facebook/page-id', {
                    facebook_page_url: url
                });

                const pageId = response.data.id;

                this.pageUrlIsValid = !!pageId;

                return pageId;

            } catch (error) {
                // If the system returns a 400 error it's telling us that
                // the request went through but the page is invalid
                const responseCode = get(error, 'response.status');
                if (error.response && responseCode == 400) {
                    this.pageUrlIsValid = false;
                    this.pageUrlErrors = ['This is not a valid Facebook page URL'];
                } else {
                    console.error('Error validation Facbeook page', error);
                }
            } finally {
                this.loading = false;
            }
        },
        onPageUrlInput: debounce(async function(e) {
            const { value } = e.target;
            const pageId = await this.validatePageUrl(value);
            if (pageId) {
                this.getPage(pageId);
            }
        }, 500),
        async onContinue() {
            // If the page access request has already been initiated continue & exit
            if (!this.onboardingPageSetupIncomplete) {
                return this.$store.dispatch('goToNextStep');
            }

            try {
                this.error = null;
                this.loadingPageRequest = true;

                // Create the annotation to speed up the process
                await this.$http.post('facebook_page_annotations', {
                    dealer_id: this.currentDealer.id,
                    facebook_page_id: this.page.id,
                });

                // Initiate the page request workflow
                await this.$http.post(`dealers/${this.currentDealer.id}/page`, {
                    facebook_page_url: `https://www.facebook.com/${this.page.id}`,
                    sync: true,
                    disable_email_notification: true,
                });

                // Check the facebook page feature to update the status
                this.$store.dispatch('updateOnboardingStatus', {
                    features: this.currentStepActiveFeatures
                });

                // Once we know we're complete continue!
                this.completed = true;
                this.$store.dispatch('goToNextStep');
            } catch (error) {
                console.error('Error making page request', error);
                this.error = 'There was an error adding this Facebook page.  Please try again or contact support if the problem continues';
            } finally {
                this.loadingPageRequest = false;
            }
        },
        onDealerPageUpdate(pages) {
            // Use the new page for fall back to the current
            // if a change request is in progress
            if (pages.newPage || pages.currentPage) {
                this.page = pages.newPage || pages.currentPage;
            }

            // Check the facebook page feature to update the status
            this.$store.dispatch('updateOnboardingStatus', {
                features: this.currentStepActiveFeatures
            });
        }
    }
};
</script>

<style lang="scss" scoped>

.page-callout {
    border: 1px solid rgba($gray, 0.3);
    background-color: rgba($gray, 0.03);
    padding: 20px;
}
</style>
